<div class="app-content content d-flex justify-content-center align-items-center">
    <div class="content-wrapper">
        <div class="content-body p-4">
            <ng-container *ngIf="isLoaded">

                <div class="logo-img"></div>

                <form [formGroup]="searchClientForm" novalidate class="form pt-2" autocomplete="false">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-body">

                                <div class="form-group">
                                    <input autocomplete="off" id="clientName" required type="text" class="form-control" (keyup)="setMinValidation(searchClientForm.controls['clientName'].value)"
                                        name="clientName" placeholder="Client Name" formControlName="clientName" (blur)="trimValue('clientName', searchClientForm.controls['clientName'].value)">

                                        
                                            <div *ngIf="simpleSearchLoading && isLoading" id="loading-bar-spinner" class="spinner">
                                               <div class="spinner-icon"></div>
                                            </div>
                                         
                                    <div class="form-group">
                                        <div *ngIf="searchClientForm.controls['clientName'].invalid && (searchClientForm.controls['clientName'].dirty || searchClientForm.controls['clientName'].touched)"
                                            class="form_alert">
                                            <span class="ft-alert-circle"></span>
                                            <div *ngIf="searchClientForm.controls['clientName'].errors.required">
                                                Client name is required.
                                            </div>
                                            <div *ngIf="searchClientForm.controls['clientName'].errors.minlength">
                                                Client name must be at least 3 characters long.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="search_b p-1" *ngIf="searchResults && !searchClientForm.controls['advanced'].value && searchResults && searchClientForm.controls['clientName'].value.length > 2" [class.search_b_scroll]="searchResults.length > 4">
                                        <ul class="mb-0">
                                            <ng-container *ngIf="searchResults.length == 0"> 
                                                <a href="javascript:void(0);" class="seach_a">  <li class="search_pad text-center">No result found</li></a>
                                            </ng-container>
                                            <ng-container *ngIf="searchResults.length >0" > 
                                                <ng-container *ngFor="let client of searchResults; let j = index"> 
                                                    <a href="/client/view?id={{client.id}}" class="seach_a"><li class="search_pad">{{client.firstName + ' ' + client?.middleName + ' ' +client.lastName}}</li></a>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                     </div>
                                </div>
                                
                                <ng-container *ngIf="searchClientForm.controls['advanced'].value">
                                    <div class="form-group">
                                        <input autocomplete="off" id="xeppoCode" type="text" class="form-control" (blur)="trimValue('xeppoCode', searchClientForm.controls['xeppoCode'].value)"
                                            name="xeppoCode" placeholder="Client Code / Xeppo Code"
                                            formControlName="xeppoCode">
                                        <div class="form-group">
                                            <div *ngIf="searchClientForm.controls['xeppoCode'].invalid && (searchClientForm.controls['xeppoCode'].dirty || searchClientForm.controls['xeppoCode'].touched)"
                                                class="form_alert">
                                                <span class="ft-alert-circle"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input autocomplete="off" id="mobileNumber" type="text" class="form-control" (blur)="trimValue('mobileNumber', searchClientForm.controls['mobileNumber'].value)"
                                                name="mobileNumber" placeholder="Mobile Number"
                                                formControlName="mobileNumber">
                                            <div class="form-group">
                                                <div *ngIf="searchClientForm.controls['mobileNumber'].invalid && (searchClientForm.controls['mobileNumber'].invalid.dirty || searchClientForm.controls['mobileNumber'].invalid.touched)"
                                                    class="form_alert">
                                                    <span class="ft-alert-circle"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <input autocomplete="off" id="email" type="email" class="form-control" (blur)="trimValue('email', searchClientForm.controls['email'].value)"
                                                name="email" placeholder="Email Address" formControlName="email">
                                            <div class="form-group">
                                                <div *ngIf="searchClientForm.controls['email'].invalid && (searchClientForm.controls['email'].dirty || searchClientForm.controls['email'].touched)"
                                                    class="form_alert">
                                                    <span class="ft-alert-circle"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row d-flex align-items-center">
                                    <div class="col-md-6" [class.col-md-12]="!searchClientForm.controls['advanced'].value">
                                        <div class="d-flex align-items-center" [class.float-right]="searchClientForm.controls['advanced'].value" [class.justify-content-center]="!searchClientForm.controls['advanced'].value">
                                            <span class="pr-2">Advanced</span>
                                            <span>
                                                <label class="switch mb-0">
                                                    <input (change)="removeValidation()" type="checkbox"
                                                        formControlName="advanced">
                                                    <span class="slider round"></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6" *ngIf="searchClientForm.controls['advanced'].value">
                                        <div class="btn-group">
                                            <ng-container>
                                                <button (click)="searchClients(content)"
                                                    class="btn appointment_btn btn-sm border_all_radius">Search
                                                    Now</button>
                                                <ng-template #content let-modal>
                                                    <div class="modal-content">
                                                        <div class="modal-header a_modal">
                                                            <button (click)="hideModal()" type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="table-responsive"
                                                                [class.tb-height]="searchResults && searchResults.length >= 13">
                                                                <table class="table table-striped mb-0">
                                                                    <thead
                                                                        *ngIf="searchResults && searchResults.length > 0">
                                                                        <tr>
                                                                            <th class="border-top-0">Name</th>
                                                                            <th class="border-top-0">ClientCode</th>
                                                                            <th class="border-top-0">XeppoCode</th>
                                                                            <th class="border-top-0">Mobile No
                                                                            </th>
                                                                            <th class="border-top-0">Email</th>
                                                                        </tr>

                                                                    </thead>
                                                                    <tbody *ngIf="searchResults">
                                                                        <ng-container *ngIf="searchResults.length > 0">
                                                                            <tr class="pointer"
                                                                                *ngFor="let data of searchResults; let i = index"
                                                                                (click)="submit(data.id)">
                                                                                <td class="text-truncate">
                                                                                    {{data.firstName ?
                                                                                    data.firstName + ' ' + data.lastName  : '-'}}
                                                                                </td>
                                                                                <td
                                                                                    class="text-truncate text-overflow-code">
                                                                                    {{data.code ? data.code :
                                                                                    '-'}}
                                                                                </td>
                                                                                <td
                                                                                    class="text-truncate text-overflow-code">
                                                                                    {{data.xeppoCode ? data.xeppoCode :
                                                                                    '-'}}
                                                                                </td>
                                                                                <td class="text-truncate text-overflow">
                                                                                    {{data.mobileNumber ? data.mobileNumber
                                                                                    :
                                                                                    '-'}}
                                                                                </td>
                                                                                <td class="text-truncate">
                                                                                    {{data.email ? data.email :
                                                                                    '-'}}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <tr
                                                                            *ngIf="searchResults && searchResults.length === 0">
                                                                            <div class="text-center p-1 pb-1">
                                                                                No Data Found
                                                                            </div>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <!-- <div [hidden]="(!searchResults) || (itemsPerPage > totalRecordsCount)" class="grid-pagination-wr col-12">
                                                                    <div class="bg-white d-flex justify-content-between align-items-center mb-3 px-2 rounded-bottom">
                                                                      <span>Showing
                                                                        {{ (this.currentPage == 1) ? this.currentPage : ((this.currentPage * this.itemsPerPage) -  this.itemsPerPage + 1)}}  to
                                                                        {{ (this.itemsPerPage == this.searchResults?.length) ? (this.currentPage * this.itemsPerPage) :  this.totalRecordsCount }} of
                                                                        {{ this.totalRecordsCount }} entries
                                                                      </span>
                                                                      <pagination
                                                                        [boundaryLinks]="showBoundaryLinks"
                                                                        [directionLinks]="showDirectionLinks"
                                                                        [totalItems]="this.totalRecordsCount"
                                                                        [itemsPerPage]="this.itemsPerPage"
                                                                        (pageChanged)="searchClients(content, $event)"
                                                                        [maxSize]="15">
                                                                      </pagination>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #clientListModal let-modal>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="clientListModalLable" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="border-top-0">Full Name</th>
                                    <th class="border-top-0">ClientCode / XeppoCode</th>
                                    <th class="border-top-0">Mobile No</th>
                                    <th class="border-top-0">Email</th>
                                </tr>

                            </thead>
                            <tbody *ngIf="searchResults && searchResults.length > 0">

                                <tr class="pointer" *ngFor="let data of searchResults; let i = index"
                                    (click)="submit(data.id)">
                                    <td class="text-truncate">
                                        {{data.firstName ? data.firstName : '-'}}
                                    </td>
                                    <td class="text-truncate">
                                        {{data.code ? data.code : '-'}}
                                    </td>
                                    <td class="text-truncate">
                                        {{data.mobile ? data.mobile : '-'}}
                                    </td>
                                    <td class="text-truncate">
                                        {{data.email ? data.email : '-'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    </div>
</ng-template>
<!-- <ngx-ui-loader></ngx-ui-loader> -->
